module.exports = new Promise(((remoteName, remoteUrlEnvName) => {
  const logError = message => console.info(message);
  const errorFormatter = e => (e instanceof Error ? `(${e.message})` : '');

  const remoteUrl = window._env_[remoteUrlEnvName];

  const proxy = () => ({
    get: request => {
      try {
        return window[remoteName].get(request);
      } catch (e) {
        logError(
          `Unable to get ${request} for ${remoteName} ${errorFormatter(e)}`
        );
        return undefined;
      }
    },
    init: arg => {
      try {
        return window[remoteName].init(arg);
      } catch (e) {
        logError(
          `Remote container ${remoteName} may already be initialized ${errorFormatter(
            e
          )}`
        );
        return undefined;
      }
    }
  });

  return resolve => {
    const script = document.createElement('script');
    script.src = remoteUrl;

    script.onload = () => resolve(proxy());
    script.onerror = error => {
      console.error(`error loading ${remoteName}`, { error });
      const proxy = {
        get: () => {
          return Promise.resolve(() => () =>
            `Could not load the ${remoteName} mfe, please try again later`
          );
        },
        init: () => {
          return;
        }
      };
      resolve(proxy);
    };

    document.head.appendChild(script);
  };
})("eeDigital", "REACT_APP_RUNTIME_EE_DIGITAL_REMOTE_URL"));